import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginForm from '../Components/Registration & Login/LoginForm';
import RegistrationForm from '../Components/Registration & Login/RegistrationForm';
import StudentDashboard from '../Components/Dashboard/StudentDashboard';
import FrontDashboard from '../Components/Dashboard/FrontDashboard';
import LoginSelect from '../Components/Registration & Login/LoginSelect';
import StudentLogin from '../Components/Registration & Login/LoginTypes/StudentLogin';
import TeacherLogin from '../Components/Registration & Login/LoginTypes/TeacherLogin';
import FunScholarAdmin from '../Components/Registration & Login/LoginTypes/FunScholarAdmin';
import InstrituteLogin from '../Components/Registration & Login/LoginTypes/InstrituteLogin';
import TeacherDashboard from '../Components/Dashboard/TeacherDashboard';
import AdminDashboard from '../Components/Dashboard/AdminDashboard';
import Instritutedashboard from '../Components/Dashboard/Instritutedashboard';
import Board from '../Components/Dashboard/SubMenu/Board';
// import PrivateRoute from './PrivateRoute';

const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FrontDashboard />} />
        <Route path="/loginform" element={<LoginForm />} />
        <Route path="/registrationform" element={<RegistrationForm />} />
        <Route path="/studentdashboard" element={<StudentDashboard />} />
        <Route path="/teacherdashboard" element={<TeacherDashboard />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/instritutedashboard" element={<Instritutedashboard />} />
        <Route path="/frontdashboard" element={<FrontDashboard />} />
        <Route path="/loginselect" element={<LoginSelect />} />
        <Route path="/studentlogin" element={<StudentLogin />} />
        <Route path="/teacherlogin" element={<TeacherLogin />} />
        <Route path="/funscholaradmin" element={<FunScholarAdmin />} />
        <Route path="/instritutelogin" element={<InstrituteLogin />} />
        <Route path="/board" element={<Board />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;
