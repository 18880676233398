import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Board = () => {
  const [boardDetails, setBoardDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchBoardDetails = async () => {
      try {
        const response = await axios.get('/api/board');
        setBoardDetails(response.data);
      } catch (error) {
        console.error('Error fetching board details:', error);
        setErrorMessage('Error fetching board details');
      }
    };

    fetchBoardDetails();
  }, []);

  return (
    <div>
      <h2 className="text-center my-4">Board Details</h2>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      {boardDetails.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Board Code</th>
              <th>Board Name</th>
            </tr>
          </thead>
          <tbody>
            {boardDetails.map((board) => (
              <tr key={board.BoardCode}>
                <td>{board.BoardCode}</td>
                <td>{board.BoardName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No board details available</p>
      )}
    </div>
  );
};

export default Board;
