import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import Board from './SubMenu/Board'
import '../../ALL Css/ALL Dashboard CSS/AdminDashboard.css';

const initialCentreState = {
  CentreCode: '',
  CentreName: '',
  Country: '',
  State: '',
  Pincode: '',
  City: '',
  Add1: '',
  Add2: '',
  Landmark: '',
  Mobile: '',
  MobileVerified: false,
  EmailID: '',
  EmailVerified: false,
};

const AdminDashboard = () => {
  const [menuData, setMenuData] = useState([]);
  const [centreDetails, setCentreDetails] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCentre, setSelectedCentre] = useState(null);
  const [newCentre, setNewCentre] = useState(initialCentreState);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedBoardCode, setSelectedBoardCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setShowEditOptions] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.post('/api/menu');
        setMenuData(response.data);
      } catch (error) {
        setErrorMessage('Error fetching menu data');
      }
    };

    const fetchCentres = async () => {
      try {
        const response = await axios.post('/api/centres');
        setCentreDetails(response.data);
      } catch (error) {
        console.error('Error fetching centres:', error);
        setErrorMessage('Error fetching centres');
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
    fetchCentres();
  }, []);
  const handleMenuClick = (menuCode) => {
    setActiveMenu((prev) => (prev === menuCode ? null : menuCode));
    setActiveSubMenu(null);
  };

  const handleSubMenuClick = (subItem) => {
    if (subItem.MenuName === 'Centre') {
      setActiveSubMenu(subItem.MenuCode);
      const selectedCentre = centreDetails.find(centre => String(centre.CentreCode) === String(subItem.MenuCode));
      setSelectedCentre(selectedCentre || initialCentreState);
    } else if (subItem.MenuName === 'Board') {
      setActiveSubMenu('Board');
      setSelectedCentre(null);
    } else {
      setSelectedCentre(null);
      setActiveSubMenu(null);
      setSelectedBoardCode(null);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewCentre((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const exportToCSV = () => {
    const headers = ["CentreCode", "CentreName", "Add1", "Add2", "Landmark", "Mobile", "EmailID"];
    const csvRows = [
      headers.join(','),
      ...centreDetails.map(centre => [
        centre.CentreCode,
        centre.CentreName,
        centre.Add1,
        centre.Add2,
        centre.Landmark,
        centre.Mobile,
        centre.EmailID,
      ].join(','))
    ];

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'centres.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleAddNewCentre = async () => {
    try {
      const method = isEdit ? 'PUT' : 'POST';
      const url = isEdit
        ? `/api/centres/${selectedCentre}`
        : '/api/centres';

      const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newCentre),
      });

      if (response.ok) {
        const addedCentre = await response.json();
        setCentreDetails((prev) => isEdit
          ? prev.map((centre) => (centre.CentreCode === selectedCentre ? addedCentre : centre))
          : [...prev, addedCentre]
        );
        alert(isEdit ? 'Centre updated successfully' : 'Centre added successfully');
        setShowForm(false);
        setNewCentre(initialCentreState);
      } else {
        throw new Error(isEdit ? 'Failed to update centre' : 'Failed to add centre');
      }
    } catch (error) {
      console.error('Error saving centre:', error);
      setErrorMessage(error.message);
    }
  };

  const handleEdit = (centre) => {
    setNewCentre(centre);
    setSelectedCentre(centre.CentreCode);
    setIsEdit(true);
    setShowForm(true);
    setShowEditOptions(centre.CentreCode);
  };

  const handleAddNewClick = () => {
    setNewCentre(initialCentreState);
    setIsEdit(false);
    setShowForm(true);
  };

  const handleDelete = async (centreCode) => {
    try {
      const response = await fetch(`/api/centres/${centreCode}`, { method: 'DELETE' });
      if (response.ok) {
        setCentreDetails((prev) => prev.filter((centre) => centre.CentreCode !== centreCode));
        alert('Centre deleted successfully');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete centre');
      }
    } catch (error) {
      console.error('Error deleting centre:', error);
      setErrorMessage(error.message);
    }
  };

  const filteredCentreDetails = centreDetails.filter((centre) =>
  (centre?.CentreCode?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.CentreName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.Address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.Landmark?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.Mobile?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.Country?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.Pincode?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    centre?.EmailID?.toLowerCase().includes(searchQuery.toLowerCase()))
  );


  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentCentres = filteredCentreDetails.slice(indexOfFirstEntry, indexOfLastEntry);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredCentreDetails.length / entriesPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };


  const handleSearchClick = () => {
    setIsSearchExpanded(true);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsSearchExpanded(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const getMenuIcon = (menuName) => {
    switch (menuName) {
      case 'Academics':
        return <i className="bi bi-building"></i>; 
      case 'Study':
        return <i class="bi bi-backpack2"></i>; 
      case 'Reports':
        return <i className="bi bi-bar-chart"></i>;
      default:
        return <i className="bi bi-building"></i>
    }
  };

  return (
    <><div className='Navbar'>
      <span class="Menu-View-list" onClick={toggleSidebar}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="30" viewBox="0 0 48 48">
        <linearGradient id="9iHXMuvV7brSX7hFt~tsna_Rdp3AydLFY2A_gr1" x1="12.066" x2="34.891" y1=".066" y2="22.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsna_Rdp3AydLFY2A_gr1)" d="M43,15H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,14.1,44.1,15,43,15z"></path><linearGradient id="9iHXMuvV7brSX7hFt~tsnb_Rdp3AydLFY2A_gr2" x1="12.066" x2="34.891" y1="12.066" y2="34.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsnb_Rdp3AydLFY2A_gr2)" d="M43,27H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,26.1,44.1,27,43,27z"></path><linearGradient id="9iHXMuvV7brSX7hFt~tsnc_Rdp3AydLFY2A_gr3" x1="12.066" x2="34.891" y1="24.066" y2="46.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsnc_Rdp3AydLFY2A_gr3)" d="M43,39H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,38.1,44.1,39,43,39z"></path>
      </svg></span>
    </div>
      <div className="container-fluid">
        {isSidebarVisible && (
          <div className="sidebar">
            {menuData.filter(item => [100, 200, 300].includes(item.MenuCode)).map(item => (
              <div key={item.MenuCode} className="menu-item">
                <div className="menu-title" onClick={() => handleMenuClick(item.MenuCode)}>{getMenuIcon(item.MenuName)}
                  {item.MenuName}
                  <i className={`bi ${activeMenu === item.MenuCode ? 'bi-chevron-up' : 'bi-chevron-down'} arrow-icon`}></i>
                </div>
                {activeMenu === item.MenuCode && (
                  <ul className="submenu-list">
                    {menuData.filter(subItem => subItem.ParentCode === item.MenuCode).map(subItem => (
                      <li key={subItem.MenuCode} className="submenu-item" onClick={() => handleSubMenuClick(subItem)}>
                        {subItem.MenuName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        )}

        <main className="col-md-9-details-pane">
        {activeSubMenu === 'Board' && <Board boardCode={selectedBoardCode} />}
          {selectedCentre && (
            <>
              <h2 className="text-center my-4">Centre Details</h2>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <div className="All-Search-bar">
                <div className='Search-bar' onBlur={handleBlur}>
                  {isSearchExpanded && (
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      className={`search-input ${isSearchExpanded ? 'search-input-expanded' : ''}`}
                      onChange={(e) => setSearchQuery(e.target.value)} />
                  )}
                  <button onClick={handleSearchClick} className="search-btn">
                    <i className="bi bi-search"></i>
                  </button>
                  <button className="btn btn-primary my-3" onClick={handleAddNewClick}>
                    <i className="bi bi-plus-circle"></i>
                  </button>
                  <button className="btn-secondarymy-3" style={{ border: "none" }} onClick={exportToCSV}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                      <rect width="16" height="9" x="28" y="15" fill="#21a366"></rect><path fill="#185c37" d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"></path><rect width="16" height="9" x="28" y="24" fill="#107c42"></rect><rect width="16" height="9" x="12" y="15" fill="#3fa071"></rect><path fill="#33c481" d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"></path><path fill="#21a366" d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"></path><path d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z" opacity=".05"></path><path d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z" opacity=".07"></path><path d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z" opacity=".09"></path><linearGradient id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1" x1="4.725" x2="23.055" y1="14.725" y2="33.055" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#18884f"></stop><stop offset="1" stop-color="#0b6731"></stop></linearGradient><path fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)" d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"></path><path fill="#fff" d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z"></path>
                    </svg>
                  </button>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <label className="mr-2">Show</label>
                  <select
                    name="entries"
                    className="form-control form-control-sm w-auto"
                    onChange={(e) => entriesPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <label className="ml-2">Entries</label>
                </div>
              </div>


              {loading ? (
                <p>Loading centres...</p>
              ) : (

                <table className="table table-bordered rounded-table">
                  <thead>
                    <tr>
                      <th>Centre Code</th>
                      <th>Centre Name</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Country</th>
                      <th>Pincode</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCentres.map((centre) => (
                      <tr key={centre.CentreCode}>
                        <td>{centre.CentreCode}</td>
                        <td>{centre.CentreName}</td>
                        <td>{`${centre.Add1} ${centre.Add2}`}</td>
                        <td>{centre.City}</td>
                        <td>{centre.State}</td>
                        <td>{centre.Country}</td>
                        <td>{centre.Pincode}</td>
                        <td>{centre.Mobile}</td>
                        <td>{centre.EmailID}</td>
                        <td>
                          <button className="btn btn-secondary" onClick={() => handleEdit(centre)}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24">
                              <path d="M 16.9375 1.0625 L 3.875 14.125 L 1.0742188 22.925781 L 9.875 20.125 L 22.9375 7.0625 C 22.9375 7.0625 22.8375 4.9615 20.9375 3.0625 C 19.0375 1.1625 16.9375 1.0625 16.9375 1.0625 z M 17.3125 2.6875 C 18.3845 2.8915 19.237984 3.3456094 19.896484 4.0214844 C 20.554984 4.6973594 21.0185 5.595 21.3125 6.6875 L 19.5 8.5 L 15.5 4.5 L 16.9375 3.0625 L 17.3125 2.6875 z M 4.9785156 15.126953 C 4.990338 15.129931 6.1809555 15.430955 7.375 16.625 C 8.675 17.825 8.875 18.925781 8.875 18.925781 L 8.9179688 18.976562 L 5.3691406 20.119141 L 3.8730469 18.623047 L 4.9785156 15.126953 z"></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

<div className="pagination">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
              <span>{currentPage}</span>
              <button onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredCentreDetails.length / entriesPerPage)}>Next</button>
              </div>
            </>
          )}

          {showForm && (
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{isEdit ? 'Edit Centre' : 'Add New Centre'}</h5>

                    {isEdit && (
                      <button
                        onClick={() => handleDelete(selectedCentre)}
                        class="bin-button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 39 7"
                          class="bin-top"
                        >
                          <line stroke-width="4" stroke="white" y2="5" x2="39" y1="5"></line>
                          <line
                            stroke-width="3"
                            stroke="white"
                            y2="1.5"
                            x2="26.0357"
                            y1="1.5"
                            x1="12"
                          ></line>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 33 39"
                          class="bin-bottom"
                        >
                          <mask fill="white" id="path-1-inside-1_8_19">
                            <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                            ></path>
                          </mask>
                          <path
                            mask="url(#path-1-inside-1_8_19)"
                            fill="white"
                            d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                          ></path>
                          <path stroke-width="4" stroke="white" d="M12 6L12 29"></path>
                          <path stroke-width="4" stroke="white" d="M21 6V29"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 89 80"
                          class="garbage"
                        >
                          <path
                            fill="white"
                            d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                    <button type="button" className="Close-button" onClick={() => setShowForm(false)}>
                      <span>&times;</span>
                    </button>

                  </div>
                  <form className='New-Form' onSubmit={(e) => { e.preventDefault(); handleAddNewCentre(); }}>
                    <div className="modal-body">
                      {Object.keys(newCentre).map((key) => {
                        if (key === 'MobileVerified' || key === 'EmailVerified') {
                          return (
                            <div className="form-group form-check" key={key}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={key}
                                checked={newCentre[key]}
                                onChange={handleInputChange} />
                              <label className="form-check-label">{key === 'MobileVerified' ? 'Mobile Verified' : 'Email Verified'}</label>
                            </div>
                          );
                        }
                        return (
                          <div className="form-group" key={key}>
                            <input
                              type={key === 'EmailID' ? 'email' : 'text'}
                              className="form-control"
                              name={key}
                              placeholder={key.replace(/([A-Z])/g, ' $1')}
                              value={newCentre[key]}
                              onChange={handleInputChange}
                              required={key !== 'Add2'} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                      <button type="button" className="btn btn-secondary" onClick={() => setShowForm(false)}>Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </main>
      </div></>
  );
};

export default AdminDashboard;
