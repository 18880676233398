import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../ALL Css/Login.css';

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const controlSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password || !role) {
      setError("All fields are required");
      return;
    }
    setError("");

    try {
      const response = await fetch('http://localhost:5000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, role }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.role.toLowerCase() !== role.toLowerCase()) {
          setError("The role you selected does not match your account role.");
        } else {
          navigate(`/${role.toLowerCase()}-dashboard`); // Dynamic redirection based on role
        }
      } else {
        const message = await response.text();
        setError(message);
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  };

  return (
    <div className='Login-container'>
      <div className='login-left'></div>
      <div className='login-right'>
        <form className='Login-form' onSubmit={controlSubmit}>
          <h2>Login</h2>
          {error && <p style={{ color: 'red' }} className='error-message'>{error}</p>}
          <div className='input-box'>
            <label htmlFor='email'>Email</label>
            <input
              className='login-input'
              name='email'
              placeholder='Enter Email'
              value={email}
              type='text'
              onChange={(e) => setEmail(e.target.value)}
              id='email'
              required
              autoComplete='off'
            />

            <label htmlFor='password'>Password</label>
            <input
              className='login-input'
              name='password'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              id='password'
              required
              autoComplete='new-password'
            />
            <svg
              onClick={togglePasswordVisibility}
              style={{ cursor: 'pointer' }}
              viewBox="0 0 576 512"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
            </svg>
          </div>
          <div className='login-role'>
            <select value={role} onChange={(e) => setRole(e.target.value)} required>
              <option value="">Select Role</option>
              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
              <option value="Admin">Admin</option>
              <option value="Institute">Institute</option>
            </select>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='login-remember'>
                <label>
                  <input type="checkbox" />Remember Me
                </label>
                <a className='forgot-link' href="/passwordreset">Forgot Password?</a>
              </div>
            </div>
          </div>
          <button type='submit' className='login-btn'>
            Login
            <div className="icon">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </button>
          <div className='login-register-button-wrapper'>
            <div className='login-register-text'>
              <span>New?
                <a href='/registrationform' className='login-register-btn'>
                  Sign up for Free!
                </a>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
